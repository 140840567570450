import * as t from "io-ts";

import { Nullable } from "./Nullable";
import { MomentDateTime } from "./Moment";
import { User } from "./User";
import { ListManagerM } from "./Manager";
import { ListCustomerM } from "./Customer";

export const MessageSenderUser = t.type({
    type: t.literal("user"),
    user: User,
});

export const MessageSenderManger = t.type({
    type: t.literal("manager"),
    manager: ListManagerM,
});

export const MessageSenderCustomer = t.type({
    type: t.literal("customer"),
    customer: ListCustomerM,
});

export const MessageSender = t.union([MessageSenderUser, MessageSenderManger, MessageSenderCustomer]);

export const MessageRecipient = t.type({
    label: t.string,
    id: t.string,
});

export const Message = t.type({
    id: t.number,
    createdAt: MomentDateTime,
    subject: t.string,
    subject_es: Nullable(t.string),
    text: t.string,
    text_es: Nullable(t.string),
    textTranslated: Nullable(t.string),
    isRead: t.boolean,
    isSystem: t.boolean,
    isTodo: Nullable(t.boolean),
    deleteDate: Nullable(MomentDateTime),
    sender: MessageSender,
    bookingId: Nullable(t.number),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MessageSenderUser = t.TypeOf<typeof MessageSenderUser>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MessageSenderManger = t.TypeOf<typeof MessageSenderManger>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MessageSenderCustomer = t.TypeOf<typeof MessageSenderCustomer>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MessageSender = t.TypeOf<typeof MessageSender>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MessageRecipient = t.TypeOf<typeof MessageRecipient>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Message = t.TypeOf<typeof Message>;
