import React from "react";
import Dinero from "dinero.js";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import TrackingLink from "tracking-link";

import { SearchResult, SearchObjectType, SearchResultFinca, SearchResultApartment } from "types";
import PictureSrcset from "jslib/components/PictureSrcset";
import { ImageTupleBreakpointsFull } from "jslib/types";

import SortIndexDebug from "./SortIndexDebug";
import FincaRating from "./FincaRating";
import "./SearchResult.css";

type ReactImageGalleryItemCustom = ReactImageGalleryItem & { img: ImageTupleBreakpointsFull };

declare module "react-image-gallery" {
    interface ReactImageGalleryProps {
        useWindowKeyDown?: boolean;
    }
}

const humanSearchObjectType = (sot: SearchObjectType): string => {
    switch (sot) {
        case "finca":
            return "Finca";
        case "agritourism":
            return "Agrotourismus";
        case "house":
            return "Ferienhaus";
        case "flat":
            return "Ferienwohnung";
        case "fincahotel":
            return "Fincahotel";
    }
};

type BrokenImageMinSizeProps = React.ImgHTMLAttributes<HTMLImageElement> & {
    brokenWidth: number;
    brokenHeight: number;
};

const BrokenImageMinSize: React.FC<BrokenImageMinSizeProps> = ({ brokenWidth, brokenHeight, style, ...props }) => {
    const ref = React.useRef(null);
    const [isBroken, setIsBroken] = React.useState(false);
    const onError = React.useCallback(() => setIsBroken(true), []);
    const brokenStyle = isBroken ? { width: `${brokenWidth}px`, height: `${brokenHeight}px` } : {};
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img {...props} ref={ref} onError={onError} style={{ ...style, ...brokenStyle }} />;
};

const RenderGalleryItem: React.FC<{ item: ReactImageGalleryItemCustom; fincaName: string; isFullscreen: boolean }> = ({
    item,
    fincaName,
    isFullscreen,
}) => {
    return isFullscreen ? (
        <>
            <BrokenImageMinSize
                className="image-gallery-image"
                src={item.fullscreen}
                alt={item.originalAlt}
                height={item.originalHeight}
                width={item.originalWidth}
                title={item.originalTitle}
                brokenWidth={1920}
                brokenHeight={1080}
            />
            {item.description && <span className="image-gallery-description">{item.description}</span>}
        </>
    ) : (
        <PictureSrcset
            image={item.img.breakpoints}
            alt={item.description || fincaName}
            className="image-gallery-image"
        />
    );
};

const SearchResultComponent: React.FC<{
    result: SearchResult;
    onDisplay?: (fincaPublicId: number) => void;
    onClick?: (event: any) => Promise<unknown>;
}> = ({ result, onDisplay, onClick }) => {
    const hasBooking = result.bookings.length > 0;
    const nights = hasBooking ? result.bookings[0].end.diff(result.bookings[0].start, "days") : null;
    const minPrice = Dinero.minimum(result.bookings.map((b) => b.price));
    const allPricesEqual = result.bookings
        .map((b, idx, bookings) => idx === 0 || bookings[idx - 1].price.equalsTo(b.price))
        .every((i) => i);
    const gallery = React.useRef(null);
    const domElement = React.useRef<HTMLElement>(null);

    React.useLayoutEffect(() => {
        if (domElement.current && onDisplay) {
            const observer = new IntersectionObserver(
                (changes, observer) => {
                    changes.forEach((change) => {
                        if (change.intersectionRatio > 0.9) {
                            onDisplay(result.finca.publicId);
                        }
                    });
                },
                {
                    root: null,
                    rootMargin: "0px",
                    threshold: 0.9,
                },
            );

            observer.observe(domElement.current);

            return () => observer.disconnect();
        }
    }, [onDisplay, result.finca.publicId]);

    const hideMobileHeader = React.useCallback((hide: boolean) => {
        if (hide) {
            document.getElementsByClassName("header-mobile-sticky")[0].classList.add("d-none");
            document.getElementsByClassName("header-mobile")[0].classList.add("d-none");
        } else {
            document.getElementsByClassName("header-mobile-sticky")[0].classList.remove("d-none");
            document.getElementsByClassName("header-mobile")[0].classList.remove("d-none");
        }
    }, []);

    const images = React.useMemo(
        () => {
            const mkDesc = (img: ImageTupleBreakpointsFull, obj: SearchResultFinca | SearchResultApartment) =>
                img.caption ??
                ("numApartments" in obj
                    ? `${humanSearchObjectType(obj.searchObjectType)} ${obj.name}`
                    : `Apartment ${obj.name}`);

            const images: ReactImageGalleryItemCustom[] = [];
            if (result.finca.mainImage) {
                images.push({
                    original: "",
                    fullscreen: result.finca.mainImage.full[0][0],
                    img: result.finca.mainImage,
                    description: `${humanSearchObjectType(result.finca.searchObjectType)} ${result.finca.name}`,
                });
            }
            result.finca.previewGallery.forEach((image) => {
                images.push({
                    original: "",
                    fullscreen: image.full[0][0],
                    img: image,
                    description: mkDesc(image, result.finca),
                });
            });
            if (result.bookings.length > 0) {
                result.bookings.forEach((booking) => {
                    result.finca.apartments[booking.apartmentPublicId].previewGallery.forEach((image) => {
                        images.push({
                            original: "",
                            fullscreen: image.full[0][0],
                            img: image,
                            description: mkDesc(image, result.finca.apartments[booking.apartmentPublicId]),
                        });
                    });
                });
            } else {
                Object.values(result.finca.apartments)
                    .slice(0, 3)
                    .forEach((apartment) => {
                        apartment.previewGallery.forEach((image) => {
                            images.push({
                                original: "",
                                fullscreen: image.full[0][0],
                                img: image,
                                description: mkDesc(image, apartment),
                            });
                        });
                    });
            }
            return images;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [result.finca.id],
    );

    return (
        <article className="search-result" ref={domElement}>
            <div className="search-result__wrapper">
                <div className="finca-card">
                    <div className="finca-card__body">
                        {result.finca.mainImage && (
                            <ImageGallery
                                ref={gallery}
                                items={images}
                                showFullscreenButton={false}
                                showPlayButton={false}
                                showThumbnails={false}
                                useWindowKeyDown={false}
                                useBrowserFullscreen={false}
                                onScreenChange={(isFullscreen) => hideMobileHeader(isFullscreen)}
                                onClick={
                                    () => {} /* Lightbox disabled 
                                    gallery.current && (gallery.current as any).toggleFullScreen() */
                                }
                                renderItem={(item) => (
                                    <RenderGalleryItem
                                        item={item as ReactImageGalleryItemCustom}
                                        fincaName={result.finca.name}
                                        isFullscreen={
                                            !!(gallery.current && (gallery.current as any).state.isFullscreen)
                                        }
                                    />
                                )}
                            />
                        )}

                        <div className="finca-card__data">
                            <header>
                                <h2 className="h4 mb-0">
                                    <TrackingLink href={result.finca.url} onClick={onClick}>
                                        <span className="text-muted">
                                            {humanSearchObjectType(result.finca.searchObjectType)}
                                        </span>{" "}
                                        {result.finca.name}
                                    </TrackingLink>
                                </h2>
                            </header>

                            {hasBooking && (
                                <h3 className="h6">
                                    {result.hasMore && "Mehr als "}
                                    {result.bookings.length}{" "}
                                    {result.bookings.length === 1 ? "Buchungsmöglichkeit" : "Buchungsmöglichkeiten"}
                                </h3>
                            )}

                            <div className="finca-number-bar">
                                {result.finca.searchObjectType === "fincahotel" ? (
                                    <div className="mx-auto">{result.finca.numApartments} Kategorien</div>
                                ) : result.finca.searchObjectType === "agritourism" ? (
                                    <div className="mx-auto">{result.finca.numApartments} Apartments</div>
                                ) : (
                                    <>
                                        <div data-toggle="tooltip" data-placement="bottom" title="Personen">
                                            <span className="fa fa-users mr-2"></span>
                                            {result.finca.maxAdditionalBeds ? (
                                                <>
                                                    {result.finca.numBeds}+{result.finca.maxAdditionalBeds}
                                                </>
                                            ) : (
                                                <>{result.finca.numBeds}</>
                                            )}
                                        </div>
                                        <div data-toggle="tooltip" data-placement="bottom" title="Schlafzimmer">
                                            <span className="fa fa-bed mr-2"></span>
                                            {result.finca.numBedrooms}
                                        </div>
                                        <div data-toggle="tooltip" data-placement="bottom" title="Badezimmer">
                                            <span className="fa fa-bath mr-2"></span>
                                            {result.finca.numBathrooms}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="finca-card__text">
                                <ul className="list-checks--green mb-0">
                                    {result.finca.searchLines.map((line: string, idx: number) => (
                                        <li key={idx}>{line}</li>
                                    ))}
                                </ul>
                                {hasBooking ? (
                                    <div className="finca-card__price">
                                        {!allPricesEqual && <small>ab</small>}
                                        {allPricesEqual && result.bookings[0].specialConditionPrice.isNegative() && (
                                            <del className="text-muted">
                                                {result.bookings[0].price
                                                    .subtract(result.bookings[0].specialConditionPrice)
                                                    .toFormat()}
                                            </del>
                                        )}
                                        <h3>{minPrice.toFormat()}</h3>
                                        <small>für {nights} Nächte</small>
                                    </div>
                                ) : result.finca.pricerange[0] && result.finca.pricerange[1] ? (
                                    <div className="finca-card__pricerange">
                                        <h3>
                                            {result.finca.pricerange[0].toFormat()}
                                            <br />
                                            <span>&ndash;</span>
                                            <br />
                                            {result.finca.pricerange[1].toFormat()}
                                        </h3>
                                        <small>pro Nacht</small>
                                    </div>
                                ) : null}
                            </div>

                            <div className="d-md-none mt-3">
                                <FincaRating finca={result.finca} />
                            </div>
                        </div>
                    </div>
                    <div className="finca-card__footer">
                        {result.finca.cancellationAdText && (
                            <div className="finca-card__free-cancellation">
                                <span className="badge badge-success">
                                    <i className="fa-regular fa-fw fa-thumbs-up"></i>
                                </span>{" "}
                                {result.finca.cancellationAdText}
                            </div>
                        )}
                        {hasBooking &&
                            result.bookings.map((booking, idx) => (
                                <div key={idx} className="finca-card__multibooking">
                                    <div className="finca-card__multibooking-apartment">
                                        <TrackingLink
                                            href={result.finca.apartments[booking.apartmentPublicId].url}
                                            className="h6"
                                            onClick={onClick}
                                        >
                                            {result.finca.numApartments > 1 &&
                                                `${result.finca.apartments[booking.apartmentPublicId].type} `}
                                            {result.finca.apartments[booking.apartmentPublicId].name}
                                        </TrackingLink>
                                        <span>
                                            von {booking.start.format("dd., L")} bis {booking.end.format("dd., L")}
                                        </span>
                                    </div>
                                    <div className="finca-card__multibooking-price">
                                        <span className="text-primary font-weight-bold">
                                            {booking.specialConditionPrice.isNegative() && (
                                                <del className="text-muted font-weight-normal">
                                                    {booking.price.subtract(booking.specialConditionPrice).toFormat()}
                                                </del>
                                            )}{" "}
                                            {booking.price.toFormat()}
                                        </span>{" "}
                                        <small>
                                            für {booking.end.diff(booking.start, "days")} Nächte und{" "}
                                            {booking.adults + booking.children + booking.babies} Person
                                            {booking.adults + booking.children + booking.babies !== 1 && "en"}
                                        </small>
                                    </div>
                                </div>
                            ))}
                        {result.hasMore && (
                            <div className="finca-card__multibooking--more">
                                Weitere mögliche Termine finden Sie im{" "}
                                <TrackingLink href={result.finca.url + "/online-buchen"} onClick={onClick}>
                                    Buchungskalender
                                </TrackingLink>
                                !
                            </div>
                        )}
                        <div className="finca-card__footer-rating">
                            <div className="d-none d-md-flex">
                                <FincaRating finca={result.finca} />
                            </div>
                            <div className="d-flex">
                                {result.sortIndex && <SortIndexDebug result={result} />}
                                <TrackingLink href={result.finca.url} className="btn btn-primary" onClick={onClick}>
                                    Finca anschauen
                                    <i className="fa fa-fw fa-caret-right" />
                                </TrackingLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    );
};

export default SearchResultComponent;
